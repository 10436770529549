<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-24 15:09:00
 * @LastEditTime: 2021-09-26 17:07:08
 * @Description: 生产物流-车辆/工地列表
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\components\productionMap\siteVehicles.vue
 -->


<template>
    <div class="siteVehicles">
        <ul class="siteVehiclesNav">
            <template v-for="(item, index) in tabs">
                <li :class="{'active': activeTab===index}" :key="`tab_${index}`" @click="handleActiveTab(index)">
                    {{ item.name }}<span>{{ item.num }}</span>
                </li>
            </template>
        </ul>
        <div class="vehicleData">
            <!-- 在施工地 -->
            <ul v-if="activeTab===0" class="classification">
                <li
                    :key="`construction_${index}`"
                    v-for="(item, index) in constructionTabs"
                    :class="{'active': constructionActive===index}"
                    @click="constructionSiteTabClick(index, item)"
                >
                    <h3>{{ item.num }}</h3>
                    <p>{{ item.name }}</p>
                </li>
            </ul>
            <!-- 运输车辆 -->
            <ul v-if="activeTab===1" class="classification">
                <li
                    v-for="(item, index) in carTabs"
                    :key="`car_${index}`"
                    :class="{'active': carActive===index}"
                    class="tab-item"
                    @click="carTabsClick(index, item)"
                >
                    <h3>{{ item.num }}</h3>
                    <p>{{ item.name }}</p>
                </li>
            </ul>
            <!-- 异常监控 -->
            <div v-if="activeTab===2">
                <div>
                    <el-carousel
                        :autoplay="false"
                        arrow="never"
                        height="0.76rem"
                    >
                        <el-carousel-item
                            v-for="(item, index) in abnormalTabs"
                            :key="`carousel_${index}`"
                        >
                            <ul class="classification">
                                <li
                                    v-for="(v, vIndex) in item"
                                    :key="`abnormal_${index}_${vIndex}`"
                                    :class="{'active': abnormalActive===v.sortNum}"
                                    class="tab-item"
                                    @click="clickAbnormal(v.sortNum)"
                                >
                                    <h3>{{ v.num }}</h3>
                                    <p>{{ v.name }}</p>
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="vehicleList">
            <h4>车辆列表</h4>
            <div class="vehicleTable" v-if="activeTab!==2">
                <el-input placeholder="请输入内容" v-model="vehicleSearchVal" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="handleCarlistPrj"></el-button>
                </el-input>
                <table>
                    <thead>
                        <tr>
                            <th>小号</th>
                            <th>
                                速度<br>(km/h)
                            </th>
                            <th>
                                距离<br>(km)
                            </th>
                            <th>
                                时间<br>(h)
                            </th>
                            <th>
                                发货<br>单号
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in tableData">
                            <tr :key="`table_${index}`">
                                <td>{{ item.plate_small_number }}</td>
                                <td>{{ item.speed }}</td>
                                <td>{{ item.to_distance }}</td>
                                <td>{{ item.to_time }}</td>
                                <td @click.stop="transInfo(item.pcb_number)">
                                    {{ item.pcb_number|handlePcbNum }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div v-else class="abnormal-main">
                <el-input placeholder="请输入关键词(车牌号、车号、异常内容)" v-model="abnormalSearch" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="handleAbnormalSearch"></el-button>
                </el-input>
                <div class="abnormal-list infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <ul
                        v-for="item in abnormalData"
                        :key="item.id"
                    >
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.error_content"
                            placement="left"
                            popper-class="abnormalinfo-tooltip"
                        >
                            <li>
                                <span>
                                    <span class="plate-small-num">{{ item.no }}</span>
                                    <span>{{ item.error_name }}</span>
                                </span>
                                <span class="time">
                                    <span class="dot"></span>
                                    <span>{{ item.error_datetime }}</span>
                                </span>
                            </li>
                        </el-tooltip>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import largeScreenFun from '../../JS/index.js';

export default {
    name: 'site-vehicles',
    components: {},
    props: {
        projectsAddress: {
            type: [Array, Object, String ],
        },
        carStatistics: {
            type: [Array, Object, String ],
        },
        anomalyStatistics: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            time: '',
            stationId: '',

            // 在施工地、运输车辆
            vehicleSearchVal: '',
            tableData: [],

            // 异常监控
            abnormalSearch: '',
            abnormalData: [],
            abnormalIndex: 0,

            // 顶部tab切换
            tabs: [
                { name: '在施工地：', num: 0 },
                { name: '运输车辆：', num: 0 },
                { name: '异常监控：', num: 0 },
            ],
            activeTab: 0,

            // 在施工地类型
            constructionTabs: [
                { name: '运输中', num: 0, sortNum: 4, projectCodeList: [], classifiedData: [] },
                { name: '未完成', num: 0, sortNum: 3, projectCodeList: [], classifiedData: [] },
                { name: '未运输', num: 0, sortNum: 2, projectCodeList: [], classifiedData: [] },
            ],
            constructionActive: 0,
            projectCodeList: [],

            // 运输车辆类型
            carTabs: [
                { name: '运输中', num: 0, sortNum: 2, classifiedData: [] },
                { name: '返场中', num: 0, sortNum: 3, classifiedData: [] },
                { name: '在工地', num: 0, sortNum: 5, classifiedData: [] },
                { name: '未运输', num: 0, sortNum: 4, classifiedData: [] },
            ],
            carActive: 0,

            // 异常监控类型
            abnormalTabs: [
                [
                    { name: '停车超时', num: 0, sortNum: 1 },
                    { name: '行驶超速', num: 0, sortNum: 2 },
                    { name: '路线偏离', num: 0, sortNum: 3 },
                    { name: '罐反转', num: 0, sortNum: 4 },
                ],
                [
                    { name: '油耗异常', num: 0, sortNum: 5 },
                    { name: '压车异常', num: 0, sortNum: 6 },
                    { name: '其他异常', num: 0, sortNum: 7 },
                ],
            ],
            abnormalActive: 1,
        };
    },
    computed: {},
    watch: {
        projectsAddress: {
            handler: function (val) {
                if (val.length > 0) {
                    this.constructionTabs.forEach(item => {
                        item.num = 0;
                    });
                    val.map(item => {
                        if (item.status === 1) {
                            this.constructionTabs[2].num += 1;
                            this.constructionTabs[2].classifiedData.push(item);
                            this.constructionTabs[2].projectCodeList.push(item.project_code);
                        } else if (item.status === 3) {
                            this.constructionTabs[0].num += 1;
                            this.constructionTabs[0].classifiedData.push(item);
                            this.constructionTabs[0].projectCodeList.push(item.project_code);
                        } else {
                            this.constructionTabs[1].num += 1;
                            this.constructionTabs[1].classifiedData.push(item);
                            this.constructionTabs[1].projectCodeList.push(item.project_code);
                        }
                    });
                    this.tabs[0].num = val.length;
                    this.projectCodeList = this.constructionTabs[0].projectCodeList;
                    this.handleCarlistPrj();
                }
            },
            deep: true,
        },
        carStatistics(val) {
            const trans = [];
            const back = [];
            const onSite = [];
            const notWork = [];
            val.forEach(item => {
                if ([6].indexOf(item.status) > -1) {
                    trans.push(item);
                } else if ([7, 73].indexOf(item.status) > -1) {
                    onSite.push(item);
                } else if ([77, 8].indexOf(item.status) > -1) {
                    back.push(item);
                } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                    notWork.push(item);
                }

            });
            this.tabs[1].num = val.length;
            this.carTabs.forEach(item => {
                if (item.name === '运输中') {
                    item.num = trans.length;
                    item.classifiedData = trans;
                } else if (item.name === '返场中') {
                    item.num = back.length;
                    item.classifiedData = back;
                } else if (item.name === '在工地') {
                    item.num = onSite.length;
                    item.classifiedData = onSite;
                } else if (item.name === '未运输') {
                    item.num = notWork.length;
                    item.classifiedData = notWork;
                }
            });
        },
        anomalyStatistics(val) {
            if (val) {
                this.abnormalTabs = this.abnormalTabs.map(a => {
                    const temp = a.map(v => {
                        const arr = val.filter(item => item.error_type === v.sortNum);
                        v.num = arr[0].error_count;
                        return v;
                    });
                    return temp;
                });
                let sumCount = 0;
                val.map(item => {
                    sumCount += item.error_count;
                });
                this.tabs[2].num = sumCount;
                this.handleExceptions();
            }
        },
    },
    created() {
        this.time = this.$parent.time;
        this.stationId = this.$parent.stationId;
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 顶部切换tab
        handleActiveTab(index) {
            this.activeTab = index;
            this.abnormalIndex = 0;
            this.constructionActive = 0;
            this.carActive = -1;
            this.activeWorkOrder = '';
            this.vehicleSearchVal = '';
            this.handleCarlistPrj();
        },
        // 工地切换
        constructionSiteTabClick(index, item) {
            this.constructionActive = index;
            this.projectCodeList = item.projectCodeList;
            const returnData = {
                type: 'siteLoading',
                data: item.classifiedData,
            };
            this.handleCarlistPrj();
            this.$emit('mapDataLoading', returnData);
        },
        // 运输车辆切换
        carTabsClick(index, item) {
            this.carActive = index;
            const returnData = {
                type: 'vehicleLoading',
                data: item.classifiedData,
            };
            this.handleCarlistPrj();
            this.$emit('mapDataLoading', returnData);
        },
        // 异常监控
        clickAbnormal(type) {
            if (this.abnormalActive !== type) {
                this.abnormalActive = type;
            } else {
                this.abnormalActive = -1;
            }
            this.abnormalData = [];
            this.handleExceptions();
        },
        // 异常记录列表
        async handleExceptions() {
            const res = await largeScreenFun.getExceptions(this.abnormalSearch, this.abnormalIndex, this.abnormalActive);
            if (res) {
                this.abnormalData = [...this.abnormalData, ...res.rows];
            }
        },
        // 异常记录列表回车搜索
        handleAbnormalSearch() {
            this.tableData = [];
            this.handleExceptions();
        },
        // 异常列表-无限滚动
        load() {
            this.abnormalIndex++;
            this.handleExceptions();
        },
        // 处理在施工地下车辆列表
        async handleCarlistPrj() {
            let res = [];
            this.tableData = [];
            if (this.activeTab === 0) {
                res = await this.getCarlistPrj();
            } else if (this.activeTab === 1) {
                res = await this.getCarlistCarriage();
            }
            this.tableData = res;
        },
        // 在施工地模块下拉取车辆信息
        getCarlistPrj() {
            const data = {
                keywords: this.vehicleSearchVal,
                stationId: this.stationId,
                project_status: this.constructionActive,
                project_code_list: this.projectCodeList,
                vehicle_status: -1,
            };
            const path = '/interfaceApi/production/vehicleinfo/get_carlist_prj';
            return this.$axios.$post(path, data, { defEx: true });
        },
        // 拉取运输车辆-车辆列表
        getCarlistCarriage() {
            const data = {
                keywords: this.vehicleSearchVal,
                stationId: this.stationId,
                carriage_status: this.carActive,
            };
            const path = '/interfaceApi/production/vehicleinfo/get_carlist_carriage';
            return this.$axios.$post(path, data, { defEx: true });
        },
        // 运输单详情
        transInfo(data) {
            this.$toast({
                title: true,
                text: '发货单详情',
                type: 'eject',
                width: '10rem',
                height: '6rem',
                t_url: 'productionManagement/logisticsMonitoring/billInfo',
                viewPosition: 'view',
                extr: { pcb_number: data },
            });
        },
    },
    filters: {
        // 派车表编号截取
        handlePcbNum(val) {
            let str = '';
            if (val) {
                str = val.substr(7, val.length);
            }
            return str;
        },
    },
};
</script>
<style lang="stylus" scoped></style>